import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { Card, CardMedia, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';

var md5 = require('md5');
//DEJAR BASENAME A RAIZ
const companyBasename =
  process.env.NODE_ENV === "development" ? "/erp2015/" : "";
const defaultValues = {
  debtor_no:0,
  cust_name: "",
  tax_id: "",
  curr_code: "",
  cust_ref: "",
  postal_code: "",
  cfdi_regimen_fiscal: "",
  timbrado_uso_cfdi: "",
};


function App() {
  const [formValues, setFormValues] = useState(defaultValues);
  const [companyId, setCompanyId] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [razonSocialCliente, setRazonSocialCliente] = useState("");
  const [clienteId, setClienteId] = useState("");
  const [clienteHash, setClienteHash] = useState("");
  const [token, setToken] = useState("");
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState('');
  const [alertContent, setAlertContent] = useState('');
  const [errorLogin, setErrorLogin] = useState(true);

  //OBTENER EL TOKEN DE LA SESION
  React.useEffect(() => {
    //GET del data enviado con base64
    const query = new URLSearchParams(window.location.search);
    const data = query.get('data');
    const decodedData = atob(data);
    const jsonData = JSON.parse(decodedData);
    setCompanyId(jsonData.company_id);
    setRazonSocial(jsonData.razon_social);
    setCompanyUrl(jsonData.company_url);
    setClienteHash(jsonData.cliente_hash);
    setClienteId(jsonData.cliente_id);
    setToken(window.reactData.token);
    localStorage.setItem("CoreAPIToken", window.reactData.token);
    axios.defaults.baseURL =  `https://${jsonData.company_url}${companyBasename}`;
    axios.defaults.timeout = 180000;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if(token !== ""){
      setOpen(true)
      setAlertContent('Obteniendo datos del cliente...');
      setAlert('success');
      setErrorLogin(false);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${localStorage.getItem("CoreAPIToken")}`,
      };
      getCliente()
      .then((res) => {
        if (res.data.data[0]) {
          const newData = {
            debtor_no:res.data.data[0].customer_id,
            cust_name: res.data.data[0].CustName,
            tax_id: res.data.data[0].tax_id,
            curr_code: res.data.data[0].curr_code,
            cust_ref: res.data.data[0].cust_ref,
            postal_code: res.data.data[0].postal_code,
            cfdi_regimen_fiscal: res.data.data[0].regimen,
            timbrado_uso_cfdi: res.data.data[0].timbrado_uso_cfdi
          };
          setRazonSocialCliente(res.data.data[0].CustName);
          setFormValues(newData);
        } else {
          console.error("SHOULD NEVER HAPPEN", res);
        }
      })
      .catch((err) => {
        // NOT OK
        setOpen(true)
        setAlertContent('Error al obtener cliente');
        setAlert('error');
        console.error("GET ERROR", err);
      });
    }else{
      setOpen(true)
      setAlertContent('Error al hacer login');
      setAlert('error');
      setErrorLogin(true);
    }
  }, [token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  
  const handleSubmit = (event) => {
    setErrorLogin(true);
    var hash = md5(`saasmexico${formValues.debtor_no}`);
    if(clienteHash === hash){
      var razonMayuscula = razonSocialCliente.toLocaleUpperCase();
      var newRazon = formValues.cust_name;
      var newRazonUpper = newRazon.toLocaleUpperCase();
      event.preventDefault();
      if(!razonMayuscula.includes(newRazonUpper)){
        setOpen(true)
        setAlertContent('Error al actualizar cliente, verifique el cambio de Razón Social');
        setAlert('error');
        console.error("Error al actualizar cliente");
        setErrorLogin(false);
      }else{
        let url = `/api/v3/sales/customers/${formValues.debtor_no}`;
        putCliente(url,formValues)
        .then((res) => {
          if(res.status === 200){
            setOpen(true)
            setAlertContent('Cliente Actualizado');
            setAlert('success');
            setErrorLogin(false);
          }else{
            setOpen(true)
            setAlertContent('Error al actualizar cliente');
            setAlert('error');
            console.error("Error al actualizar cliente");
            setErrorLogin(false);
          }
        })
        .catch((err) => {
          // NOT OK
          setOpen(true)
          setAlertContent(`Error al actualizar cliente: ${err}`);
          setAlert('error');
          console.error("Error al actualizar cliente", err);
          setErrorLogin(false);
        });
      }
    }else{
      setOpen(true)
      setAlertContent('Cliente no coincide');
      setAlert('error');
      console.error("Error al actualizar cliente, verifique los datos");
      setErrorLogin(false);
    }
  };

  const handleChangeRegimen = (event) => {
    setFormValues({
      ...formValues,
      cfdi_regimen_fiscal: event.target.value,
    });
  };

  const handleChangeTimbrado = (event) => {
    setFormValues({
      ...formValues,
      timbrado_uso_cfdi: event.target.value,
    });
  };

  //FUNCIONES API

  const putCliente = (url, data) => {
    console.log("data", data);
    return axios.put(url, data);
  };

  const getCliente = () =>{
    let url = `/api/v3/sales/customers/${clienteId}`;
    return axios.get(url);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <img src="/logo_saasmexico.png" alt="logo"/>
        </Paper>
      </Grid>
      <br/>
      <Grid  item xs={12} container alignItems="center" direction="column">
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            {razonSocial} solicita actualizar tus datos de CFDI V4
          </Typography>
        </Grid>
        <br/>
        <Grid item xs={4}>
            <Card className="center">
              <a href="https://saasmexico.com/" target="_blank" rel="noreferrer">
                <CardMedia
                  component="img"
                  image="/banner.jpg"
                  alt="Banner"
                ></CardMedia>
              </a>
            </Card>
        </Grid>
        <br/>
          <TextField
            id="tax-input"
            name="tax_id"
            label="RFC"
            type="text"
            value={formValues.tax_id}
            disabled={true}
            style={{width: '33%'}}
          />
        <br/>
          <TextField
            id="cust_name-input"
            name="cust_name"
            label="Razón Social"
            type="text"
            value={formValues.cust_name}
            onChange={handleInputChange}
            style={{width: '33%'}}
          />
        <br/>
          <TextField
            id="cp-input"
            name="postal_code"
            label="Código Postal"
            type="text"
            value={formValues.postal_code}
            onChange={handleInputChange}
            style={{width: '33%'}}
          />
        <br/>
          <Select
            labelId="regimen-simple-select-label"
            id="regimen-select"
            value={formValues.cfdi_regimen_fiscal}
            label="Régimen Fiscal"
            onChange={handleChangeRegimen}
            style={{width: '33%'}}
          >
            <MenuItem value={601}>601 - General de Ley Personas Morales</MenuItem>
            <MenuItem value={603}>603 - Personas Morales con Fines no Lucrativos</MenuItem>
            <MenuItem value={605}>605 - Sueldos y Salarios e Ingresos Asimilados a Salarios</MenuItem>
            <MenuItem value={606}>606 - Arrendamiento</MenuItem>
            <MenuItem value={607}>607 - Régimen de Enajenación o Adquisición de Bienes</MenuItem>
            <MenuItem value={608}>608 - Demás ingresos</MenuItem>
            <MenuItem value={610}>610 - Residentes en el Extranjero sin Establecimiento Permanente en México</MenuItem>
            <MenuItem value={611}>611 - Ingresos por Dividendos (socios y accionistas)</MenuItem>
            <MenuItem value={612}>612 - Personas Físicas con Actividades Empresariales y Profesionales</MenuItem>
            <MenuItem value={614}>614 - Ingresos por intereses</MenuItem>
            <MenuItem value={615}>615 - Régimen de los ingresos por obtención de premios</MenuItem>
            <MenuItem value={616}>616 - Sin obligaciones fiscales</MenuItem>
            <MenuItem value={620}>620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos</MenuItem>
            <MenuItem value={621}>621 - Incorporación Fiscal</MenuItem>
            <MenuItem value={622}>622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</MenuItem>
            <MenuItem value={623}>623 - Opcional para Grupos de Sociedades</MenuItem>
            <MenuItem value={624}>624 - Coordinados</MenuItem>
            <MenuItem value={625}>625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</MenuItem>
            <MenuItem value={626}>626 - Régimen Simplificado de Confianza</MenuItem>
          </Select>
        <br/>
        <Select
            labelId="timbrado-simple-select-label"
            id="timbrado-select"
            value={formValues.timbrado_uso_cfdi}
            label="Uso CFDI"
            onChange={handleChangeTimbrado}
            style={{width: '33%'}}
          >
            <MenuItem value={"G01"}>G01 - Adquisición de mercancías</MenuItem>
            <MenuItem value={"G02"}>G02 - Devoluciones, descuentos o bonificaciones</MenuItem>
            <MenuItem value={"G03"}>G03 - Gastos en general</MenuItem>
            <MenuItem value={"I01"}>I01 - Construcciones</MenuItem>
            <MenuItem value={"I02"}>I02 - Mobiliario y equipo de oficina por inversiones</MenuItem>
            <MenuItem value={"I03"}>I03 - Equipo de transporte</MenuItem>
            <MenuItem value={"I04"}>I04 - Equipo de cómputo y accesorios</MenuItem>
            <MenuItem value={"I05"}>I05 - Dados, troqueles, moldes, matrices y herramental</MenuItem>
            <MenuItem value={"I07"}>I06 - Comunicaciones telefónicas</MenuItem>
            <MenuItem value={"I07"}>I07 - Comunicaciones satelitales</MenuItem>
            <MenuItem value={"I08"}>I08 - Otra maquinaria y equipo</MenuItem>
            <MenuItem value={"D01"}>D01 - Honorarios médicos, dentales y gastos hospitalarios</MenuItem>
            <MenuItem value={"D02"}>D02 - Gastos médicos por incapacidad o discapacidad</MenuItem>
            <MenuItem value={"D03"}>D03 - Gastos funerales</MenuItem>
            <MenuItem value={"D04"}>D04 - Donativos</MenuItem>
            <MenuItem value={"D05"}>D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</MenuItem>
            <MenuItem value={"D06"}>D06 - Aportaciones voluntarias al SAR</MenuItem>
            <MenuItem value={"D07"}>D07 - Primas por seguros de gastos médicos</MenuItem>
            <MenuItem value={"D08"}>D08 - Gastos de transportación escolar obligatoria</MenuItem>
            <MenuItem value={"D09"}>D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</MenuItem>
            <MenuItem value={"S01"}>S01 - Sin Efectos Fiscales</MenuItem>
            <MenuItem value={"CP01"}>CP01 - Pagos</MenuItem>
            <MenuItem value={"CN01"}>CN01 - Nómina</MenuItem>
            <MenuItem value={"P01"}>P01 - Por Definir</MenuItem>
            <MenuItem value={"09"}>09 - PPD</MenuItem>
          </Select>
        <br/>
        {errorLogin ? 
          <CircularProgress /> 
        : 
          <Button variant="contained" color="primary" type="submit">
            Actualizar Datos Fiscales
          </Button>}
        <br/>
        <Collapse in={open}>
          {open ? <Alert
            severity = {alert === 'error' ? 'error':'success'}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {alertContent}
          </Alert> : <></>}
        </Collapse>
      </Grid>
    </form>
  );
};

export default App;